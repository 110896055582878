import { ChainId, Percent, Token, WETH9 } from '@uniswap/sdk-core'
import { AbstractConnector } from '@web3-react/abstract-connector'
import {
  // fortmatic,
  injected,
  // portis,
  walletconnect,
  walletlink
} from '../connectors'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

export const LP_TOKEN_NAME = 'Swap-LP-Token'
export const LP_TOKEN_SYMBOL = 'SWAP-LP'

// a list of tokens by chain
type ChainTokenList = {
  readonly [chainId in ChainId]: Token[]
}

export const DAI = new Token(ChainId.MAINNET, '0x6B175474E89094C44Da98b954EedeAC495271d0F', 18, 'DAI', 'Dai Stablecoin')
export const USDC = new Token(ChainId.MAINNET, '0xA0b86991c6218b36c1d19D4a2e9Eb0cE3606eB48', 6, 'USDC', 'USD//C')
export const USDT = new Token(ChainId.MAINNET, '0xdAC17F958D2ee523a2206206994597C13D831ec7', 6, 'USDT', 'Tether USD')
export const COMP = new Token(ChainId.MAINNET, '0xc00e94Cb662C3520282E6f5717214004A7f26888', 18, 'COMP', 'Compound')
export const MKR = new Token(ChainId.MAINNET, '0x9f8F72aA9304c8B593d555F12eF6589cC3A579A2', 18, 'MKR', 'Maker')
export const AMPL = new Token(ChainId.MAINNET, '0xD46bA6D942050d489DBd938a2C909A5d5039A161', 9, 'AMPL', 'Ampleforth')
export const WBTC = new Token(ChainId.MAINNET, '0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599', 8, 'WBTC', 'Wrapped BTC')
export const SEPOLIA_USDT = new Token(
  ChainId.SEPOLIA,
  '0x5b943707D7932A8FA3E9D5e4189097A43077E66c',
  18,
  'USDT',
  'Tether USD'
)
export const SEPOLIA_USDC = new Token(
  ChainId.SEPOLIA,
  '0x70eF28489a141cAa5FE91aDB9093351B32D47cDB',
  6,
  'USDC',
  'USD Coin'
)
export const SEPOLIA_WBTC = new Token(
  ChainId.SEPOLIA,
  '0x00d30095a21Cbe4305C6A47c8034496D69aCC89c',
  6,
  'WBTC',
  'Wrapped BTC'
)
export const SEPOLIA_DAI = new Token(ChainId.SEPOLIA, '0xB6Ade34eb718c88916f082f2F4C223C911280Ae4', 18, 'DAI', 'Dai')

// Block time here is slightly higher (~1s) than average in order to avoid ongoing proposals past the displayed time
export const AVERAGE_BLOCK_TIME_IN_SECS = 13
export const PROPOSAL_LENGTH_IN_BLOCKS = 40_320
export const PROPOSAL_LENGTH_IN_SECS = AVERAGE_BLOCK_TIME_IN_SECS * PROPOSAL_LENGTH_IN_BLOCKS
export const TIMELOCK_ADDRESS = '0x1a9C8182C09F50C8318d769245beA52c32BE35BC'

export const COMMON_CONTRACT_NAMES: { [address: string]: string } = {
  [TIMELOCK_ADDRESS]: 'Timelock'
}

const WETH_ONLY: ChainTokenList = {
  [ChainId.MAINNET]: [WETH9[ChainId.MAINNET]],
  [ChainId.SEPOLIA]: [WETH9[ChainId.SEPOLIA]],
  [ChainId.GOERLI]: [WETH9[ChainId.GOERLI]],
  [ChainId.OPTIMISM]: [WETH9[ChainId.OPTIMISM]],
  [ChainId.OPTIMISM_GOERLI]: [WETH9[ChainId.OPTIMISM_GOERLI]],
  [ChainId.OPTIMISM_SEPOLIA]: [WETH9[ChainId.OPTIMISM_SEPOLIA]],
  [ChainId.ARBITRUM_ONE]: [WETH9[ChainId.ARBITRUM_ONE]],
  [ChainId.ARBITRUM_GOERLI]: [WETH9[ChainId.ARBITRUM_GOERLI]],
  [ChainId.ARBITRUM_SEPOLIA]: [WETH9[ChainId.ARBITRUM_SEPOLIA]],
  [ChainId.POLYGON]: [WETH9[ChainId.POLYGON]],
  [ChainId.POLYGON_MUMBAI]: [WETH9[ChainId.POLYGON_MUMBAI]],
  [ChainId.CELO]: [WETH9[ChainId.CELO]],
  [ChainId.CELO_ALFAJORES]: [WETH9[ChainId.CELO_ALFAJORES]],
  [ChainId.BNB]: [WETH9[ChainId.CELO_ALFAJORES]],
  [ChainId.BNB_TESTNET]: [WETH9[ChainId.BNB_TESTNET]],
  [ChainId.AVALANCHE]: [WETH9[ChainId.AVALANCHE]],
  [ChainId.AVALANCHE_FUJI]: [WETH9[ChainId.AVALANCHE_FUJI]],
  [ChainId.BASE_GOERLI]: [WETH9[ChainId.BASE_GOERLI]],
  [ChainId.BASE]: [WETH9[ChainId.BASE]],
  [ChainId.ZORA]: [WETH9[ChainId.ZORA]],
  [ChainId.ZORA_SEPOLIA]: [WETH9[ChainId.ZORA_SEPOLIA]],
  [ChainId.ROOTSTOCK]: [WETH9[ChainId.ROOTSTOCK]],
  [ChainId.BLAST]: [WETH9[ChainId.BLAST]]
}

// used to construct intermediary pairs for trading
export const BASES_TO_CHECK_TRADES_AGAINST: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, COMP, MKR, WBTC],
  [ChainId.SEPOLIA]: [...WETH_ONLY[ChainId.SEPOLIA], SEPOLIA_DAI, SEPOLIA_USDC, SEPOLIA_USDT, SEPOLIA_WBTC]
}

/**
 * Some tokens can only be swapped via certain pairs, so we override the list of bases that are considered for these
 * tokens.
 */
export const CUSTOM_BASES: { [chainId in ChainId]?: { [tokenAddress: string]: Token[] } } = {
  [ChainId.MAINNET]: {
    [AMPL.address]: [DAI, WETH9[ChainId.MAINNET]]
  }
}

// used for display in the default list when adding liquidity
export const SUGGESTED_BASES: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC],
  [ChainId.SEPOLIA]: [...WETH_ONLY[ChainId.SEPOLIA], SEPOLIA_DAI, SEPOLIA_USDC, SEPOLIA_USDT, SEPOLIA_WBTC]
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WETH_ONLY,
  [ChainId.MAINNET]: [...WETH_ONLY[ChainId.MAINNET], DAI, USDC, USDT, WBTC],
  [ChainId.GOERLI]: [WETH9[ChainId.GOERLI]],
  [ChainId.OPTIMISM]: [WETH9[ChainId.OPTIMISM]],
  [ChainId.OPTIMISM_GOERLI]: [WETH9[ChainId.OPTIMISM_GOERLI]],
  [ChainId.OPTIMISM_SEPOLIA]: [WETH9[ChainId.OPTIMISM_SEPOLIA]],
  [ChainId.ARBITRUM_ONE]: [WETH9[ChainId.ARBITRUM_ONE]],
  [ChainId.ARBITRUM_GOERLI]: [WETH9[ChainId.ARBITRUM_GOERLI]],
  [ChainId.ARBITRUM_SEPOLIA]: [WETH9[ChainId.ARBITRUM_SEPOLIA]],
  [ChainId.POLYGON]: [WETH9[ChainId.POLYGON]],
  [ChainId.POLYGON_MUMBAI]: [WETH9[ChainId.POLYGON_MUMBAI]],
  [ChainId.CELO]: [WETH9[ChainId.CELO]],
  [ChainId.CELO_ALFAJORES]: [WETH9[ChainId.CELO_ALFAJORES]],
  [ChainId.BNB]: [WETH9[ChainId.CELO_ALFAJORES]],
  [ChainId.BNB_TESTNET]: [WETH9[ChainId.BNB_TESTNET]],
  [ChainId.AVALANCHE]: [WETH9[ChainId.AVALANCHE]],
  [ChainId.AVALANCHE_FUJI]: [WETH9[ChainId.AVALANCHE_FUJI]],
  [ChainId.BASE_GOERLI]: [WETH9[ChainId.BASE_GOERLI]],
  [ChainId.BASE]: [WETH9[ChainId.BASE]],
  [ChainId.ZORA]: [WETH9[ChainId.ZORA]],
  [ChainId.ZORA_SEPOLIA]: [WETH9[ChainId.ZORA_SEPOLIA]],
  [ChainId.ROOTSTOCK]: [WETH9[ChainId.ROOTSTOCK]],
  [ChainId.BLAST]: [WETH9[ChainId.BLAST]],
  [ChainId.SEPOLIA]: [...WETH_ONLY[ChainId.SEPOLIA], SEPOLIA_DAI, SEPOLIA_USDC, SEPOLIA_USDT, SEPOLIA_WBTC]
}

export const PINNED_PAIRS: { readonly [chainId in ChainId]?: [Token, Token][] } = {
  [ChainId.MAINNET]: [
    [
      new Token(ChainId.MAINNET, '0x5d3a536E4D6DbD6114cc1Ead35777bAB948E3643', 8, 'cDAI', 'Compound Dai'),
      new Token(ChainId.MAINNET, '0x39AA39c021dfbaE8faC545936693aC917d5E7563', 8, 'cUSDC', 'Compound USD Coin')
    ],
    [USDC, USDT],
    [DAI, USDT]
  ]
}

export interface WalletInfo {
  connector?: AbstractConnector
  name: string
  iconName: string
  description: string
  href: string | null
  color: string
  primary?: true
  mobile?: true
  mobileOnly?: true
}

export interface ChainInfo {
  chainId: ChainId
  icon: string
  chainName: string
}

export const SUPPORTED_CHAINS: { [key: number]: ChainInfo } = {
  [ChainId.MAINNET]: {
    chainId: ChainId.MAINNET,
    chainName: 'Ethereum',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg'
  },
  [ChainId.AVALANCHE]: {
    chainId: ChainId.AVALANCHE,
    chainName: 'Avalanche C-Chain',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_avalanche.jpg'
  },
  [ChainId.ARBITRUM_ONE]: {
    chainId: ChainId.ARBITRUM_ONE,
    chainName: 'Arbitrum One',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_arbitrum.jpg'
  },
  [ChainId.BNB]: {
    chainId: ChainId.BNB,
    chainName: 'Binance Smart Chain',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_binance.jpg'
  },
  [ChainId.BNB_TESTNET]: {
    chainId: ChainId.BNB_TESTNET,
    chainName: 'BSC Testnet',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_binance.jpg'
  },
  [ChainId.OPTIMISM]: {
    chainId: ChainId.OPTIMISM,
    chainName: 'OP Mainnet',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_optimism.jpg'
  },
  [ChainId.SEPOLIA]: {
    chainId: ChainId.SEPOLIA,
    chainName: 'Sepolia',
    icon: 'https://icons.llamao.fi/icons/chains/rsz_ethereum.jpg'
  }
}

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: 'WalletConnect',
    iconName: 'walletConnectIcon.svg',
    description: 'Connect to Trust Wallet, Rainbow Wallet and more...',
    href: null,
    color: '#4196FC',
    mobile: true
  },
  WALLET_LINK: {
    connector: walletlink,
    name: 'Coinbase Wallet',
    iconName: 'coinbaseWalletIcon.svg',
    description: 'Use Coinbase Wallet app on mobile device',
    href: null,
    color: '#315CF5'
  }
  // COINBASE_LINK: {
  //   name: 'Open in Coinbase Wallet',
  //   iconName: 'coinbaseWalletIcon.svg',
  //   description: 'Open in Coinbase Wallet app.',
  //   href: 'https://go.cb-w.com/mtUDhEZPy1',
  //   color: '#315CF5',
  //   mobile: true,
  //   mobileOnly: true
  // },
  // FORTMATIC: {
  //   connector: fortmatic,
  //   name: 'Fortmatic',
  //   iconName: 'fortmaticIcon.png',
  //   description: 'Login using Fortmatic hosted wallet',
  //   href: null,
  //   color: '#6748FF',
  //   mobile: true,
  // },
  // Portis: {
  //   connector: portis,
  //   name: 'Portis',
  //   iconName: 'portisIcon.png',
  //   description: 'Login using Portis hosted wallet',
  //   href: null,
  //   color: '#4A6C9B',
  //   mobile: true,
  // },
}

export const NetworkContextName = 'NETWORK'
// default allowed slippage, in bips
export const INITIAL_ALLOWED_SLIPPAGE = 50
// 20 minutes, denominated in seconds
export const DEFAULT_DEADLINE_FROM_NOW = 60 * 20
// used for rewards deadlines
export const BIG_INT_SECONDS_IN_WEEK = (60 * 60 * 24 * 7).toString()
export const BIG_INT_ZERO = '0'
// one basis point
export const ONE_BIPS = new Percent(1, 10000)
export const BIPS_BASE = 10000
// used for warning states
export const ALLOWED_PRICE_IMPACT_LOW: Percent = new Percent(100, BIPS_BASE) // 1%
export const ALLOWED_PRICE_IMPACT_MEDIUM: Percent = new Percent(300, BIPS_BASE) // 3%
export const ALLOWED_PRICE_IMPACT_HIGH: Percent = new Percent(500, BIPS_BASE) // 5%
// if the price slippage exceeds this number, force the user to type 'confirm' to execute
export const PRICE_IMPACT_WITHOUT_FEE_CONFIRM_MIN: Percent = new Percent(1000, BIPS_BASE) // 10%
// for non expert mode disable swaps above this
export const BLOCKED_PRICE_IMPACT_NON_EXPERT: Percent = new Percent(1500, BIPS_BASE) // 15%
// used to ensure the user doesn't send so much ETH so they end up with <.01
export const MIN_ETH = '10000000000000000' // .01 ETH
export const BETTER_TRADE_LESS_HOPS_THRESHOLD = new Percent(50, 10000)
export const ZERO_PERCENT = new Percent('0')
export const ONE_HUNDRED_PERCENT = new Percent('1')
